import { AfterViewInit, Component, ComponentFactory, ContentChild,ComponentFactoryResolver, ComponentRef, ElementRef, HostBinding, HostListener, Input, OnDestroy, OnInit, SimpleChanges, ViewChild, ViewContainerRef, ViewEncapsulation } from '@angular/core';
//import { element } from '@angular/core/src/render3';
import { Router } from '@angular/router';
import { NgxXml2jsonService } from 'ngx-xml2json'; 
//import { type } from 'os';
//import { version } from 'punycode';
import { SearchService } from '../shared/services/entitySearch/search.service';
import { TaggingModelService } from '../shared/tagging-model.service';
import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';
import { TextHighlightDirective } from '../shared/text-highlight.directive';
import { EntityPopupComponent } from '../shared/entity-popup/entity-popup.component';
import { PopupComponent } from '../shared/popup/popup.component';
import { SearchinfoPopupComponent } from '../shared/searchinfo-popup/searchinfo-popup.component';
//import { stringify } from '@angular/core/src/util';
import { errorPopupComponent } from '../shared/error-popup/error-popup.component';
import { MatDialog } from '@angular/material/dialog';
import { DataService } from "../data.service";
import { Subscription } from 'rxjs';
import { Observable } from 'rxjs';
import { Console } from 'console';
import { doesNotThrow } from 'assert';
import { browserRefresh } from '../app.component';
import { ActivatedRoute } from '@angular/router';



@Component({
  selector: 'app-rdf-results',
  templateUrl: './rdf-results.component.html',
  styleUrls: ['./rdf-results.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class RdfResultsComponent implements OnInit, OnDestroy {
  @ViewChild('popover', {static: false}) ref : ElementRef;
  @ViewChild('entityPopup', {static: false}) container : any;
  @ViewChild('popupHightlight', {static: false}) higlight : any;
 // @ViewChild('popover') ref: any;
  //@ViewChild('entityPopup', { read: ViewContainerRef }) container: any;
  //@ViewChild('popupHightlight') higlight: any;
  contentType: string;
  varDatavalue: any;
  Input: any;
  textInputArea: boolean = false;
  fileIndex: number = 0;
  selectedfile: any;
  textDocument: any;
  language: string;
  checked: boolean = true;
  master_checked: boolean = true;
  master_relation_checked: boolean = true;
  master_indeterminate: boolean = false;
  master_realtion_indeterminate: boolean = false;
  multipleFiles: any[] = [];
  sluglines: any[] = [];
  socialTags: any[] = [];
  topics: any[] = [];
  versions: any[] = [];
  entities: any[] = [];
  relations: any[] = [];
  industries: any[] = [];
  companyCategory: any[] = [];
  industryTermCategory: any[] = [];
  personCategory: any[] = [];
  isLoading: boolean = false;
  companyChecked: boolean = true;
  entitiesCategory:  any[] = [];
  relationsCategory: any[] = [];
  instanceOffset: any[] = [];
  relinstanceOffset: any[] = [];
  message: string;
  subscription: Subscription;
  rawString: string;
  markedTextresult: any;
  fileName = '';
  disabled: boolean = false;
  amountOfFiles: any;
  numofFiles : number;
  anshOffset:any[]=[];
  public browserRefresh: boolean;

  constructor(private router: Router,
    private taggingDetail: TaggingModelService,
    private taggingService: SearchService,
    private parseString: NgxXml2jsonService,
    public sanitizer: DomSanitizer,
    private resolver: ComponentFactoryResolver,
    public dialog: MatDialog,
    private el: ElementRef,
    private dataServ: DataService) {  }

  ngOnDestroy() {
    //this.subscription.unsubscribe();
  }

  ngOnInit() {
    this.browserRefresh = browserRefresh;
    // console.log('refreshed?:', browserRefresh);
    if (!browserRefresh)
    {
    this.subscription = this.dataServ.currentMessage.subscribe(message => this.message = message);
    const contentClass = this.taggingDetail.contentClass;
    if (this.taggingDetail.fileInput.length > 0) {
      const length = this.taggingDetail.fileInput.length;
      if (length > 1) {
        this.multipleFiles = this.taggingDetail.fileInput;
        // console.log("multiplefiles::check",JSON.stringify(this.multipleFiles));
        this.Input = this.taggingDetail.fileInput[this.fileIndex];
        this.contentType = this.taggingDetail.fileInput[this.fileIndex]['type'];
        // console.log("File Type is :", this.contentType);
        this.selectedfile = this.Input;
        // console.log(this.selectedfile);
      } else {
        this.textInputArea = true;
        this.Input = this.taggingDetail.fileInput[this.fileIndex];
        this.contentType = this.taggingDetail.fileInput[this.fileIndex]['type'];
      }
    } else {
      this.Input = this.taggingDetail.textInput;
      this.contentType = this.getContentType(this.Input);
    }

    const calaisParams = {
      Input: this.Input,
      contentClass,
      contentType: this.contentType
    }

    this.isLoading = true;
    this.getRdfResults(calaisParams);
    } // if (!browserRefresh)
    else 
    {
      //this.router.navigate(['/tagging'], { state: { prevPage: this.router.url } });
      this.router.navigateByUrl('tagging');
    }
  }
  dynamicSort(property) {
    var sortOrder = 1;
    if(property[0] === "-") {
        sortOrder = -1;
        property = property.substr(1);
    }
    return function (a,b) {
        var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
        return result * sortOrder;
    }
}
  private getRdfResults(calaisParams: { Input: any; contentClass?: string; contentType?: string; }) {
    this.taggingService.getTaggingResults(calaisParams).subscribe(data => {

      this.isLoading = false;
      let rdfHeaderName = "RDF View"
      if (calaisParams.Input.name) rdfHeaderName += " - " + calaisParams.Input.name
      sessionStorage.setItem("RDFData", JSON.stringify({
        headerName: rdfHeaderName,
        params: data
      }));

      const doc = data.find(result => result.id);
      const formattedString = doc.info.document;
      this.rawString = doc.info.document;

      this.language = doc.meta.language;

      // console.log("Data",data);
      const sluglines = data.filter(result => {
        return result["_typeGroup"] === "sluglines";
      });
      const sortSluglines = sluglines.sort((a, b) => b.confidencelevel - a.confidencelevel);
      this.sluglines = sortSluglines;

      const socialTags = data.filter(result => {
        return result["_typeGroup"] === "socialTag"
      })
      const sortSociatags = socialTags.sort((a, b) => a.importance - b.importance);
      this.socialTags = sortSociatags;

      const topics = data.filter(result => {
        return result["_typeGroup"] === "topics";
      })
      const sortTopics = topics.sort((a, b) => b.score - a.score);
      this.topics = sortTopics;

      const versionsList = data.find(result => {
        return result["_typeGroup"] === "versions"
      })
      versionsList.version.forEach((element: any) => {
        this.versions.push(element);
      })

      const industry = data.filter(result => {
        return result["_typeGroup"] === "industry";
      })
      const sortIndustry = industry.sort((a, b) => b.relevance - a.relevance);
      this.industries = sortIndustry;

      const entities = data.filter(result => {
        return result["_typeGroup"] === "entities"
      })
      this.entities = entities;

      const relations = data.filter(result => {
        return result["_typeGroup"] === "relations"
      })
      this.relations = relations;
      
      for (var i = 0; i < this.entities.length; i++) {
        // merge objects into one with multiple props
        this.entities[i] = Object.assign(this.entities[i], {
          entity_id: i ,
          checked: true ,  
        });
      }
      for (var i = 0; i < this.entities.length; i++) 
      {
        for (var j = 0; j < this.entities[i].instances.length; j++)
        {
          // adds multiple props to object
          this.entities[i].instances[j] = Object.assign(this.entities[i].instances[j], 
          {
            instance_id: j,
            instance_used: false,
            instance_free: true
          });
        }
      }

      for (var i = 0; i < this.relations.length; i++) {
        // merge objects into one with multiple props
        this.relations[i] = Object.assign(this.relations[i], {
          relation_id: i ,
          checked: true 
        });
      }

      let groupEntities = this.entities.reduce((arr, entity) => {
        if (!arr[entity._type]) {
          arr[entity._type] = [];
        }
        arr[entity._type].push(entity);
        return arr;
      }, {})

      let groupRelations = this.relations.reduce((arr, relation) => {
        if (!arr[relation._type]) {
          arr[relation._type] = [];
        }
        arr[relation._type].push(relation);
        return arr;
      }, {})

      for (var groupName in groupEntities) {
        this.entitiesCategory.push({ CategoryType: groupName, data: groupEntities[groupName] });
        this.entitiesCategory.sort(this.dynamicSort("CategoryType"));
      }
      // this.varDatavalue = this.entitiesCategory;
      // for(let i=0;i<this.varDatavalue.length;i++){
      //   console.log("this.entitiesCategory:check..:loop::", this.varDatavalue);
      //   var x = this.varDatavalue[i].data;
      //   x.sort(this.dynamicSort("name"));
      //   this.entitiesCategory = x;
      // }
      
      for (var groupRelName in groupRelations) {
        this.relationsCategory.push({ CategoryRelType: groupRelName, data: groupRelations[groupRelName] });
      }

      for (var i = 0; i < this.entitiesCategory.length; i++) {
        // merge objects into one with multiple props
        this.entitiesCategory[i] = Object.assign(this.entitiesCategory[i], {
          entityType_checked: true,
          entityType_indeterminate: false
        });
      }

      for (var i = 0; i < this.relationsCategory.length; i++) {
        // merge objects into one with multiple props
        this.relationsCategory[i] = Object.assign(this.relationsCategory[i], {
          relationType_checked: true,
          relationType_indeterminate: false
        });
      }

      const company = this.entities.filter(category => {
        return category["_type"] === "Company";
      })
      // console.log("company :",company);
      this.companyCategory = company;
      const companyNoDuplicate = company.filter(
        (element, i) => i === company.indexOf(element)
      );
      // console.log("companyNoDuplicate :",companyNoDuplicate);
      
      this.textDocument = this.initHighlightRelation(formattedString,-1,-1);
    }, HttpErrorResponse=> {

      let errorText: string = "Default Error";
      if (HttpErrorResponse.error instanceof Error) {
        console.error('An error occurred:', HttpErrorResponse);
      } else {
        //errorText = HttpErrorResponse.error.error.status['errorDescription'];
      }
      // this.router.navigate(['/']);
      this.dataServ.changeMessage(errorText);
      this.isLoading = false;
      this.openerrorPopup(errorText);
      return;
    })
    //window.scrollTo(0, 0);
  }

  openerrorPopup(value: string) {
    //console.log("Error is:",value);
    this.dialog.open(errorPopupComponent, {
      width: '300px'
    });
  }
  
  markSuperSelections(text: string, cmarkers: any[] , startBlue: number , endBlue: number) {
    
    text = text.replace(/<Title>/ig, "<Titls>");
    text = text.replace("</Title>", "</Titls>");

    let markedText = '<br>';
    let cP = 1 ;
    let tCC = 0 ;
    //--------------------------------------------------------------------------------------------------
    let cO = 0;
    let cMT:string='entity';
    let cMST:string='other';
    let cMOT:string='other';
    let cMW:string='other';
    let cMS = 0;
    let pCMS = 0;
    let nCMS= 0;
    let cMC: boolean = false;
    let pC: boolean = true;
    let rC: boolean=false;
    let rO: boolean=false;
    let sRO: boolean=false;
    let tRO: boolean=false; 
    let relationId = 0 ;
    let rC_relationId = 0 ;
    let rO_relationId=0;
    let rC_cMW:string='other';
    let rO_cMW:string='other';
    let sRO_cMW:string='other';
    let sRO_relationId=0;
    let tRO_cMW:string='other';
    let tRO_relationId=0;
    let eC: boolean=false;
    let eC_cMST:string='other';
    let eC_cMOE=0;
    let eC_entityId=0;
    let eC_cMW:string='other';
    let eC_instanceId=0;
    let sEO:boolean=false;
    let sEO_cMST:string='other';
    let sEO_cMW:string='other';
    let sEO_cMOE=0;
    let sEO_entityId=0;
    let sEO_instanceId=0;
    let eO: boolean=false;
    let eO_cMST:string='other';
    let eO_cMW:string='other';
    let eO_cMOE=0;
    let eO_entityId=0;
    let eO_instanceId=0;
    let entityId = 0;
    let instanceId = 0;
    let DonotChangeIds:boolean=false;
    let PutFakeClassId:boolean=false;
    let classNameStr:string='other';
    let FlagEffected:boolean=false;
     //--------------------------------------------------------------------------------------------------------
    // if(this.contentType=="application/pdf"){
    //   markedText += '<h2> <span class="highlight highlight-entity">' + 'PDF' + '</span> Conversion</h2>'
    // }
    //---------------------------------------------------------------------------------------------------
    cMS=cmarkers[cO]['offset'];
    cMT=cmarkers[cO]['type'];
    cMOT=cmarkers[cO]['offsettype'];
    cMW=cmarkers[cO]['word'];
    if (cMT=='entity')
      {
        cMST= cmarkers[cO]['subtype'];
       }
   //---------------------------------------------------------------------------------------------------
  for (let p=0;tCC<text.length;p++)
  {
    //--------------------------------------------------------------------------------------------
    if(cMC && cO < cmarkers.length)
      {
        cMS=cmarkers[cO]['offset'];
        cMOT=cmarkers[cO]['offsettype'];
        cMT=cmarkers[cO]['type'];
        cMW=cmarkers[cO]['word'];
        if (cMT=='entity')
        {
          cMST = cmarkers[cO]['subtype'];
        }  
      }  // cMC && cO < cmarkers.length
    //--------------------------------------------------------------------------------------------
    if(cMC && cO < cmarkers.length-1)
      {
        cMC=false;
        if ( cMS==pCMS )
        {
          if(cMT==cmarkers[cO-1]['type'])
          {
            if(cMW.indexOf(cmarkers[cO-1]['word']) != -1)
            {
              if(cMT=='entity' && cMOT=='start')
              {
                // console.log("This is the first case word" ,cMW );
                if(cmarkers[cO-1]['offsetend'] < cmarkers[cO]['offsetend'])
                 {
                   cMST=cmarkers[cO-1]['subtype'];
                   entityId = cmarkers[cO-1]['ent_id'];
                   instanceId = cmarkers[cO-1]['inst_id'];
                   DonotChangeIds=true;
                 }
              }
            }
          }
        } // cMS==pCMS
        nCMS=cmarkers[cO+1]['offset'];
        if ( cMS==nCMS )
        {
              if(cMT=='entity' && cMOT=='start')
              {
                    // console.log("This is the second case word",cMW);
                    PutFakeClassId=true;
               } 
              if(cMT=='relation' && cMOT=='start')
              {
                    // console.log("This is the third case word",cMW);
                    PutFakeClassId=true;
              }
        } // cMS!=nCMS
      } // cO < cmarkers.length-1
    //--------------------------------------------------------------------------------------------
    //console.log("tCC and cP incremented in beginning");
      if (cMS!=pCMS && markedText !='<br>')
      {
        tCC=tCC+1;
        cP=cP+1;
       }  
      else if ( cO==cmarkers.length )
      {
        tCC=tCC+1;
        cP=cP+1;
      }
    //----------------------------------------------------------------------------------------------------
      if( tCC == cMS && cMT=='relation' && cMOT=='start')
      { 
        // closing all previous relations 
          if(sRO)
          {
            markedText += '</span></span></span>';
          }
          else if(rO)
          {
            markedText += '</span></span>';
          }
          else if(rC)
          {
            markedText += '</span>';
          }
        // Closing all previous relations
        // Starting new ones with fake class ID
        if(sRO)
        {
          markedText += '<span class="highlight-event1" ><span class="highlight-event1" ><span class="highlight-event1" >'
        }
        else if (rO)
        {
          markedText += '<span class="highlight-event1" ><span class="highlight-event1" >';
        }
        else if (rC)
        {
          markedText += '<span class="highlight-event1" >';
        }
        //------------------------------------------------------------------

        if(sRO)
        {
          tRO=true;
          tRO_relationId=cmarkers[cO]['rel_id'];
          tRO_cMW=cmarkers[cO]['word'];
        }
        else if(rO)
        {
          sRO=true;
          sRO_relationId=cmarkers[cO]['rel_id'];
          sRO_cMW=cmarkers[cO]['word'];
        }
        else if(rC)
        {
          rO=true;
          rO_relationId=cmarkers[cO]['rel_id'];
          rO_cMW=cmarkers[cO]['word']; 
        }
        else
        {
          rC=true;
          rC_relationId = cmarkers[cO]['rel_id'];
          rC_cMW=cmarkers[cO]['word'];
        }

        if(eO || eC || sEO)  
        { 
          markedText += '</span>';
        }; 

        if (PutFakeClassId)
        {
          markedText += '<span class="highlight-event1">';
        }
        else
        {
          markedText += '<span class="highlight-event" id="'+cmarkers[cO]['rel_id']+'">';
        }
        PutFakeClassId=false;

        cMC=true;
        pCMS=cMS;
        
        cO=cO+1;
        nCMS=cmarkers[cO]['offset'];
  
        if(cMS==nCMS)
        {
          pC=false;
        }  
  
       }  // tCC == cMS && cMT=='relation' && cMOT=='start'
    //-----------------------------------------------------------------------------------------------------------------------------------
    if (tCC==cMS && cMT =='entity' && cMOT=='start' )
      { 
        //if(!eO && !eC && !sEO)
        //{
          if(tRO)
          {
            markedText += '</span></span></span></span>';
          }
          else if(sRO)
          {
            markedText += '</span></span></span>';
          }
          else if(rO)
          {
            markedText += '</span></span>';
          }
          else if(rC)
          {
            markedText += '</span>';
          }
        //} 
 
        if(eO)  
        { 
          markedText += '</span>';
        }; 
        if(sEO)  
        { 
          markedText += '</span>';
        }; 
        if(eC)  
        { 
          markedText += '</span>';
        };

        
        if(eO)
        {
          sEO=true;
          sEO_cMW=cmarkers[cO]['word'];
          sEO_cMST=cmarkers[cO]['subtype'];
          sEO_entityId=cmarkers[cO]['ent_id'];
          sEO_instanceId=cmarkers[cO]['inst_id'];
          sEO_cMOE=cmarkers[cO]['offsetend'];
        }
        if(eC)
        {
          eO=true;
          eO_cMW=cmarkers[cO]['word'];
          eO_cMST=cmarkers[cO]['subtype'];
          eO_entityId=cmarkers[cO]['ent_id'];
          eO_instanceId=cmarkers[cO]['inst_id'];
          eO_cMOE=cmarkers[cO]['offsetend'];
        }
        else
        {
          eC=true;
          eC_cMW=cmarkers[cO]['word'];
          eC_cMST=cmarkers[cO]['subtype'];
          eC_entityId=cmarkers[cO]['ent_id'];
          eC_instanceId=cmarkers[cO]['inst_id'];
          eC_cMOE=cmarkers[cO]['offsetend'];
        }

        if(!DonotChangeIds)
        {
          cMST=cmarkers[cO]['subtype'];
          entityId = cmarkers[cO]['ent_id'];
          instanceId = cmarkers[cO]['inst_id'];
        }
        DonotChangeIds=false;  
    //----------------------------------------------------------------------------------------------------
        if (cMST=='Company')
        {
          if ( rC || rO || sRO || tRO )
          {
            if(PutFakeClassId)  {  classNameStr='highlight-event-company1';  }
            else { classNameStr='highlight-event-company'; }
          }
          else
          {
            if(PutFakeClassId)  {  classNameStr = 'highlight-company1'; }
            else { classNameStr = 'highlight-company'; }
          }
        }
        else
        {
          if ( rC || rO || sRO || tRO )
          {
              if(PutFakeClassId) { classNameStr='highlight-event-entity1';  }
              else { classNameStr='highlight-event-entity'; }
          }
          else
          {
            if(PutFakeClassId) { classNameStr = 'highlight-entity1';  }
            else { classNameStr = 'highlight-entity'; }
          }
        }
        
        PutFakeClassId=false;
    //---------------------------------------------------------------------------------------------------- 
              if ( tRO )
              {
              markedText += '<span class='+classNameStr+' id="'+entityId+'" accesskey="'+instanceId+'" nonce="'+rC_relationId+'" lang ="'+rO_relationId+'" elementTiming="'+sRO_relationId+'">';
              }    
              else if ( sRO )
              {
              markedText += '<span class='+classNameStr+' id="'+entityId+'" accesskey="'+instanceId+'" nonce="'+rC_relationId+'" lang ="'+rO_relationId+'" elementTiming="'+sRO_relationId+'">';
              }
              else if ( rO )
              {
              markedText += '<span class='+classNameStr+' id="'+entityId+'" accesskey="'+instanceId+'" nonce="'+rC_relationId+'" lang ="'+rO_relationId+'">';
              }
              else if ( rC )
              {
              markedText += '<span class='+classNameStr+' id="'+entityId+'" accesskey="'+instanceId+'" nonce="'+rC_relationId+'">';
              }
              else
              {
                markedText += '<span class='+classNameStr+' id="'+entityId+'" accesskey="'+instanceId+'">';
              }
      //----------------------------------------------------------------------------------------------------          
           cO=cO+1;
           cMC=true;
           pCMS=cMS;
           nCMS=cmarkers[cO]['offset'];
  
           if(cMS==nCMS)
            {
              pC=false;
            } 
  
      } //   tCC==cMS && cMT =='entity' && cMOT=='start'
    //----------------------------------------------------------------------------------------------------
      //console.log("startBlue : ",startBlue);
      //console.log("endBlue : ",endBlue);  
      if( tCC >= startBlue && tCC <= endBlue && pC)
        {
          markedText += "<span class='highlight highlight-select'>"+text.substring(tCC, cP)+"</span>"; 
        }
        else if(pC)
        {
          markedText += text.substring(tCC, cP); 
        } 
        else
        {
          pC=true;
        }
      
    //--------------------------------------------------------------------------------------------------
    if ( tCC == cMS && cMOT=='end' && cMT=='relation' && cO < cmarkers.length)
    { 
       
      markedText += '</span>';
      
      // Relation ends inside entity and entity ends inside relation

      if (sEO)
      {
        markedText += '</span></span></span>';
      }
      else if (eO)
      {
        markedText += '</span></span>';
      }
      else if (eC)
      {
        markedText += '</span>';
      }
             
      if (tRO )
      {
        tRO=false;
      }
      else if (sRO )
      {
        sRO=false;
      }
      else if (rO )
      {
        rO=false;
      }
      else if (rC)
      {
        rC=false;
      }
      // In oracle file relations ended inside entity so code added 
      
      if(eC )
      {
          if (eC_cMST=='Company') 
          {
            if ( rC || rO || sRO || tRO )
            {
              if(tRO)
              {
                markedText += '<span class="highlight-event-company" id="'+eC_entityId+'" accesskey="'+eC_instanceId+'" nonce="'+rC_relationId+'" lang ="'+rO_relationId+'" elementTiming="'+sRO_relationId+'">';
              }
              else if(sRO)
              {
                markedText += '<span class="highlight-event-company" id="'+eC_entityId+'" accesskey="'+eC_instanceId+'" nonce="'+rC_relationId+'" lang ="'+rO_relationId+'" elementTiming="'+sRO_relationId+'">';
              }
              else if (rO)
              {
                markedText += '<span class="highlight-event-company" id="'+eC_entityId+'" accesskey="'+eC_instanceId+'" nonce="'+rC_relationId+'" lang ="'+rO_relationId+'">';
              }
              else if (rC)
              {
                markedText += '<span class="highlight-event-company" id="'+eC_entityId+'" accesskey="'+eC_instanceId+'" nonce="'+rC_relationId+'">';
              }
            }
            else
            markedText += '<span class="highlight-event-company" id="'+eC_entityId+'" accesskey="'+eC_instanceId+'">';
            
          }
          else 
          {
            if ( rC || rO || sRO )
            {
                if(tRO)
                {
                  markedText += '<span class="highlight-event-entity" id="'+eC_entityId+'" accesskey="'+eC_instanceId+'" nonce="'+rC_relationId+'" lang ="'+rO_relationId+'" elementTiming="'+sRO_relationId+'">';
                }  
                else if(sRO)
                {
                  markedText += '<span class="highlight-event-entity" id="'+eC_entityId+'" accesskey="'+eC_instanceId+'" nonce="'+rC_relationId+'" lang ="'+rO_relationId+'" elementTiming="'+sRO_relationId+'">';
                }
                else if (rO)
                {
                  markedText += '<span class="highlight-event-entity" id="'+eC_entityId+'" accesskey="'+eC_instanceId+'" nonce="'+rC_relationId+'" lang ="'+rO_relationId+'">';
                }
                else if (rC)
                {
                  markedText += '<span class="highlight-event-entity" id="'+eC_entityId+'" accesskey="'+eC_instanceId+'" nonce="'+rC_relationId+'">';
                }
            }
            else
            markedText += '<span class="highlight-entity" id="'+eC_entityId+'" accesskey="'+eC_instanceId+'">';
          }     
      }

      if(eO )
      {
          if (eO_cMST=='Company') 
          {
            if ( rC || rO || sRO || tRO )
            {
              if(tRO)
              {
                markedText += '<span class="highlight-event-company" id="'+eO_entityId+'" accesskey="'+eO_instanceId+'" nonce="'+rC_relationId+'" lang ="'+rO_relationId+'" elementTiming="'+sRO_relationId+'">';
              }
              else if(sRO)
              {
                markedText += '<span class="highlight-event-company" id="'+eO_entityId+'" accesskey="'+eO_instanceId+'" nonce="'+rC_relationId+'" lang ="'+rO_relationId+'" elementTiming="'+sRO_relationId+'">';
              }
              else if (rO)
              {
                markedText += '<span class="highlight-event-company" id="'+eO_entityId+'" accesskey="'+eO_instanceId+'" nonce="'+rC_relationId+'" lang ="'+rO_relationId+'">';
              }
              else if (rC)
              {
                markedText += '<span class="highlight-event-company" id="'+eO_entityId+'" accesskey="'+eO_instanceId+'" nonce="'+rC_relationId+'">';
              }
            }
            else
            markedText += '<span class="highlight-event-company" id="'+eO_entityId+'" accesskey="'+eO_instanceId+'">';
            
          }
          else 
          {
            if ( rC || rO || sRO )
            {
                if(tRO)
                {
                  markedText += '<span class="highlight-event-entity" id="'+eO_entityId+'" accesskey="'+eO_instanceId+'" nonce="'+rC_relationId+'" lang ="'+rO_relationId+'" elementTiming="'+sRO_relationId+'">';
                }  
                else if(sRO)
                {
                  markedText += '<span class="highlight-event-entity" id="'+eO_entityId+'" accesskey="'+eO_instanceId+'" nonce="'+rC_relationId+'" lang ="'+rO_relationId+'" elementTiming="'+sRO_relationId+'">';
                }
                else if (rO)
                {
                  markedText += '<span class="highlight-event-entity" id="'+eO_entityId+'" accesskey="'+eO_instanceId+'" nonce="'+rC_relationId+'" lang ="'+rO_relationId+'">';
                }
                else if (rC)
                {
                  markedText += '<span class="highlight-event-entity" id="'+eO_entityId+'" accesskey="'+eO_instanceId+'" nonce="'+rC_relationId+'">';
                }
            }
            else
            markedText += '<span class="highlight-entity" id="'+eO_entityId+'" accesskey="'+eO_instanceId+'">';
          }     
      }
    
      //---------------------------------------------------------------------------------------------------------------------
      cO=cO+1;  
      cMC=true;
      pCMS=cMS;
      if(cO < cmarkers.length )
      {
        nCMS=cmarkers[cO]['offset'];
        if(cMS==nCMS)
          {
            pC=false;
          }
      }
      
    } // tCC == cMS && cMOT=='end'
  //---------------------------------------------------------------------------------------------------
  if ( tCC == cMS && cMOT=='end' && cMT=='entity' && cO < cmarkers.length)
  { 
       
      markedText += '</span>';

      if (sEO)
      {
        markedText += '</span></span></span>';
      }
      else if (eO)
      {
        markedText += '</span></span>';
      }
      else if (eC)
      {
        markedText += '</span>';
      }
      
          
      if (sEO )
      {
        sEO=false;
      }
      else if (eO)
      {
        eO=false;
      }
      else if (eC)
      {
        eC=false;
      }


      /*if ( sEO_cMW==cMW || sEO_cMOE==tCC )
        {
          sEO=false;
          sEO_cMST='other';
          sEO_entityId=-1;
          sEO_instanceId=-1;
          FlagEffected=true;
          //DonotChangeIds=false;
        }
      if (eO_cMW==cMW || eO_cMOE==tCC )
        {
          eO=false;
          eO_cMST='other';
          eO_entityId=-1;
          eO_instanceId=-1;
          FlagEffected=true;
          //DonotChangeIds=false;
        }
        if (eC_cMW==cMW || eC_cMOE==tCC )
        {
          eC=false;
          eC_cMST='other';
          eC_entityId=-1;
          eC_instanceId=-1;
          FlagEffected=true;
          //DonotChangeIds=false;
        }*/
    
      if(!eO && !eC )
      {
        if(tRO)
        {
          markedText += '<span class="highlight-event1" ><span class="highlight-event1" ><span class="highlight-event1" ><span class="highlight-event" id="'+sRO_relationId+'" accesskey="'+rO_relationId+'" nonce="'+rC_relationId+'" >';
        }
        else if(sRO)
        {
          markedText += '<span class="highlight-event1" ><span class="highlight-event1" ><span class="highlight-event" id="'+sRO_relationId+'" accesskey="'+rO_relationId+'" nonce="'+rC_relationId+'" >';
        }
        else if (rO)
        {
          markedText += '<span class="highlight-event1" ><span class="highlight-event" id="'+rO_relationId+'" accesskey="'+rC_relationId+'">';
        }
        else if (rC)
        {
          markedText += '<span class="highlight-event" id="'+rC_relationId+'">';
        }
      }
      
        if(tRO)
        {
          relationId=tRO_relationId;
        }  
        else if(sRO)
        {
          relationId=sRO_relationId;
        }
        else if (rO)
        {
          relationId=rO_relationId;
        }
        else if (rC)
        {
          relationId=rC_relationId;
        }
      
        if(sEO)
        {
            if (sEO_cMST=='Company') 
            {
              if ( rC || rO || sRO || tRO)
              markedText += '<span class="highlight-event-company" id="'+sEO_entityId+'" accesskey="'+sEO_instanceId+'" nonce="'+relationId+'">';
              else
              markedText += '<span class="highlight-company" id="'+sEO_entityId+'" accesskey="'+sEO_instanceId+'">';
            }
            else 
            {
              if ( rC || rO || sRO || tRO)
              markedText += '<span class="highlight-event-entity" id="'+sEO_entityId+'" accesskey="'+sEO_instanceId+'" nonce="'+relationId+'">';
              else
              markedText += '<span class="highlight-entity" id="'+sEO_entityId+'" accesskey="'+sEO_instanceId+'">';
            }     
        }
        if(eO)
        {
          if (eO_cMST=='Company') 
          {
            if ( rC || rO || sRO || tRO)
            markedText += '<span class="highlight-event-company" id="'+eO_entityId+'" accesskey="'+eO_instanceId+'" nonce="'+relationId+'">';
            else
            markedText += '<span class="highlight-company" id="'+eO_entityId+'" accesskey="'+eO_instanceId+'">';
          }
          else 
          {
            if ( rC || rO || sRO || tRO)
            markedText += '<span class="highlight-event-entity" id="'+eO_entityId+'" accesskey="'+eO_instanceId+'" nonce="'+relationId+'">';
            else
            markedText += '<span class="highlight-entity" id="'+eO_entityId+'" accesskey="'+eO_instanceId+'">';
          }     
        }
        if(eC)
        {
          if (eC_cMST=='Company') 
          {
            if ( rC || rO || sRO || tRO)
            markedText += '<span class="highlight-event-company" id="'+eC_entityId+'" accesskey="'+eC_instanceId+'" nonce="'+relationId+'">';
            else
            markedText += '<span class="highlight-company" id="'+eC_entityId+'" accesskey="'+eC_instanceId+'">';
          }
          else 
          {
            if ( rC || rO || sRO || tRO)
            markedText += '<span class="highlight-event-entity" id="'+eC_entityId+'" accesskey="'+eC_instanceId+'" nonce="'+relationId+'">';
            else
            markedText += '<span class="highlight-entity" id="'+eC_entityId+'" accesskey="'+eC_instanceId+'">';
          }     
        }
    
    cO=cO+1;  
    cMC=true;
    pCMS=cMS;
    if(cO < cmarkers.length )
    {
      nCMS=cmarkers[cO]['offset'];
      if(cMS==nCMS)
        {
          pC=false;
        }
    }
    
  } // tCC == cMS && cMOT=='end' && cMT=='entity'
//------------------------------------------------------------------------------------------------------------  
} // for(let p=0;p<text.length;p++)
    //console.log("last leftout Text",text.substring(cP));
    markedText += text.substring(cP);
    // console.log("markedText remaining now",markedText);
    return markedText;
  } // markSuperSelections()
//--------------------------------------------------------------------------------------------------------

  initHighlightRelation(str: string,offsetvalue: any,lengthvalue: any){
    
    let start=offsetvalue;
    let end=offsetvalue+lengthvalue;
    this.anshOffset.splice(0);
    this.relations.forEach((category) => {
      if(category.checked)
      {
      category.instances.forEach((instance: { offset: any; exact: any; length: any; }, index: any) => {
      this.anshOffset.push({ 'offset': instance.offset , 'offsettype':'start', 'offsetend':instance.offset + instance.length , 'word': instance.exact ,'rel_id': category.relation_id,'type':'relation' });
      this.anshOffset.push({ 'offset': instance.offset + instance.length , 'offsettype':'end', 'word': instance.exact ,'rel_id': category.relation_id,'type':'relation' });
      })  } 
     })
    this.entities.forEach((category) => {
      if(category.checked)
      {
        category.instances.forEach((instance: { offset: any; exact: any; length: any; instance_id: any}, index: any) => {
          this.anshOffset.push({ 'offset': instance.offset , 'offsettype':'start', 'offsetend':instance.offset + instance.length ,'word': instance.exact ,'ent_id': category.entity_id, 'inst_id':instance.instance_id,'type':'entity' , 'subtype':category._type});
          this.anshOffset.push({ 'offset': instance.offset + instance.length , 'offsettype':'end', 'word': instance.exact ,'ent_id': category.entity_id,'inst_id':instance.instance_id,'type':'entity','subtype':category._type })
      })  } 
      })
    const ERSortedMarkers = this.anshOffset.sort((m1: { offset: number }, m2: { offset: number }) => m1.offset-m2.offset);
    // console.log("ERSortedMarkers",ERSortedMarkers);
    if(this.anshOffset.length>0)
    {
      return this.markSuperSelections(str,ERSortedMarkers,start,end);
    }
    else
    {
      return str;
    }
    
  };
  
  master_entity_change() {
    // console.log("inside master_change");
    for (let i = 0; i < this.entitiesCategory.length; i++) {
      this.entitiesCategory[i].entityType_checked = this.master_checked;
      for (let j = 0; j < this.entitiesCategory[i].data.length; j++) {
        this.entitiesCategory[i].data[j].checked = this.entitiesCategory[i].entityType_checked;
      }
    }
    this.textDocument=this.initHighlightRelation(this.rawString,-1,-1);
  }

  entityType_change(entityCategory: { data: string | any[]; entityType_checked: any; }) {
    for (let i = 0; i < entityCategory.data.length; i++) {
      entityCategory.data[i].checked = entityCategory.entityType_checked;
    }
    this.textDocument=this.initHighlightRelation(this.rawString,-1,-1);
  }

  master_relation_change() {
    // console.log(" inside master_Relationchange() ");
    for (let i = 0; i < this.relationsCategory.length; i++) {
      this.relationsCategory[i].relationType_checked = this.master_relation_checked;
      for (let j = 0; j < this.relationsCategory[i].data.length; j++) {
        this.relationsCategory[i].data[j].checked = this.relationsCategory[i].relationType_checked;
       }
    }
    this.textDocument=this.initHighlightRelation(this.rawString,-1,-1);
  }

  retlationType_change(relationCategory: { data: string | any[]; relationType_checked: any; }) {
    // console.log("inside retlationType_change");
    for (let i = 0; i < relationCategory.data.length; i++) {
      relationCategory.data[i].checked = relationCategory.relationType_checked;
     }
     this.textDocument=this.initHighlightRelation(this.rawString,-1,-1);
  }

  list_entity_change(entityCategory: { data: string | any[]; entityType_indeterminate: boolean; entityType_checked: boolean; },data: any ) {
    // console.log("inside list_change");
    // console.log("entityCategory Selected",entityCategory);
    
    let checked_count = 0 ;
    this.textDocument=this.initHighlightRelation(this.rawString,-1,-1);
  
    for (let value = 0; value < entityCategory.data.length; value++) {
      if (entityCategory.data[value].checked)
        checked_count++;
    }
  //If checked count is equal to total items; then check the master checkbox .
    if (checked_count == entityCategory.data.length) {
        entityCategory.entityType_checked = true;
    }
  //If checked count is equal to zero; then uncheck the master checkbox .
    if (checked_count == 0) {
       entityCategory.entityType_checked = false;
    }
  }

  mark_blue(entityOrRelationCategory: { data: string | any[]; entityOrRelationType_indeterminate: boolean; entityOrRelationType_checked: boolean; },data: any ) {
    // console.log("inside make_blue");
    // console.log("Category Selected",entityOrRelationCategory);
    let offsetValue=data.instances[0]['offset'];
    let lengthValue=data.instances[0]['length'];
    //console.log("offsetValue", offsetValue);
    //console.log("lengthValue", lengthValue);
    //console.log("Text in Blue:",this.rawString.substring(offsetValue,offsetValue+lengthValue));
    this.textDocument=this.initHighlightRelation(this.rawString,offsetValue,lengthValue);
  }

  list_relation_change(relationCategory: { data: string | any[]; relationType_indeterminate: boolean; relationType_checked: boolean; },data: any) {
    // console.log("inside list_change_Relation");
    // console.log("Relation Category Selected",relationCategory);
    
    let checked_count = 0;
    this.textDocument=this.initHighlightRelation(this.rawString,-1,-1);

    for (let value = 0; value < relationCategory.data.length; value++) {
      if (relationCategory.data[value].checked)
        checked_count++;
    }
    //If checked count is equal to total items; then check the master checkbox and also set Indeterminate state to false.
    if (checked_count == relationCategory.data.length) {
      relationCategory.relationType_checked = true;
    }
    //If checked count is equal to zero; then uncheck the master checkbox .
    if (checked_count == 0) {
      relationCategory.relationType_checked = false;
    }
  }

  reloadComponent() {
    let currentUrl = this.router.url;
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        this.router.onSameUrlNavigation = 'reload';
        this.router.navigate([currentUrl]);
    }

  toggleDemoFile(fileParam: { type: string; }) {
  const contentClass = this.taggingDetail.contentClass;
  this.Input = fileParam;
  this.contentType = fileParam.type;
  this.selectedfile = fileParam;
  this.sluglines = [];
  this.socialTags = [];
  this.topics = [];
  this.versions = [];
  this.entities = [];
  this.relations = [];
  this.industries = [];
  this.companyCategory = [];
  this.industryTermCategory = [];
  this.personCategory = [];
  this.companyChecked = true;
  this.entitiesCategory = [];
  this.relationsCategory = [];
  this.instanceOffset = [];
  this.relinstanceOffset = [];
  this.anshOffset=[];
  
    const calaisparam = {
      Input: this.Input,
      contentClass,
      contentType: this.contentType
    }

    this.isLoading = true;
    this.amountOfFiles = this.taggingDetail.fileInput.length;
    // console.log("amountOfFiles:",this.amountOfFiles);
    for (var i = 0; i < this.taggingDetail.fileInput.length; i++){
      if(this.taggingDetail.fileInput[i].name == this.fileName){
        this.fileIndex = i;
        break;
      }
    }
    this.getRdfResults(calaisparam);
  }

  getContentType(input: string) {
    try {
      let parser = new DOMParser;
      var xmlDoc = parser.parseFromString(input, "application/xml");
      if (xmlDoc.documentElement.nodeName == "Document")
        return 'text/xml';
      else
        return 'text/raw';
    } catch (e) {
      return 'text/raw';
    }

  }

  expandPanel(matExpansionPanel: { close: () => void; }, event: { stopPropagation: () => void; target: EventTarget; }): void {
    event.stopPropagation(); // Preventing event bubbling

    if (!this._isExpansionIndicator(event.target)) {
      matExpansionPanel.close(); // Here's the magic
    }
  }

  private _isExpansionIndicator(target: EventTarget): boolean {
    const expansionIndicatorClass = 'mat-expansion-indicator';

    return (target['classList'] && target['classList'].contains(expansionIndicatorClass));
  }

  @HostListener('mouseleave', ['$event']) onHoverOutside() {
    // this.ref.close();
  }

  openPopup() {
    this.dialog.open(SearchinfoPopupComponent, {
      width: '600px'
    });
  }
   
  chooseFile(fileId)
  {
    const contentClass = this.taggingDetail.contentClass;
    this.Input = fileId;
    this.contentType = fileId.type;
    this.selectedfile = fileId;
    this.entitiesCategory = [];
    this.relationsCategory  = [];

    const calaisparam = {
      Input: this.Input,
      contentClass,
      contentType: this.contentType
    }

    this.isLoading = true;
    this.getRdfResults(calaisparam);
    for (var i = 0; i < this.taggingDetail.fileInput.length; i++){
      if(this.taggingDetail.fileInput[i].name == this.fileName){
        this.fileIndex = i;
        break;
        }
    }
  }

  showPageResults() {
    this.ngOnInit();
  }

  showNextFile(){
      this.fileIndex++;
      // console.log(this.fileIndex);
      this.toggleDemoFile(this.taggingDetail.fileInput[this.fileIndex])
      //this.showPageResults();
  }

  showPrevFile(){
    this.fileIndex--;
    // console.log(this.fileIndex);
    this.toggleDemoFile(this.taggingDetail.fileInput[this.fileIndex])
    //this.showPageResults();
  }
}

function formattedString(formattedString: any, offsetValue: any, lengthValue: any): any {
  throw new Error('Function not implemented.');
}

