import { Directive, HostListener, HostBinding, ElementRef } from '@angular/core';

@Directive({
  selector: '[appDropdown]'
})
export class DropdownDirective {
  @HostBinding('class.open') isOpen = false;
  @HostBinding('class.active') isActive = false;
  constructor(private elRef: ElementRef) { }

  // @HostListener('click') toggleOpen() {
  //   this.isOpen = !this.isOpen;
  //   this.isActive = !this.isActive;
  // }
  @HostListener('document:click', ['$event']) toggleOpen(event: Event) {
    this.isOpen = this.elRef.nativeElement.contains(event.target) ? !this.isOpen : false;
    this.isActive = this.elRef.nativeElement.contains(event.target) ? !this.isActive: false;
  }
}
