import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'lastword'
})
export class LastwordPipe implements PipeTransform {

  transform(value: string, args?: any): string {
    if(!value) {return ''};
    let x = [];
    x = value.toString().split('/');
    return x[x.length - 1];
  }

}
