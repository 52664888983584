import { Component, OnInit, OnDestroy } from '@angular/core';
import { AuthService } from './shared/services/auth.service';
import { NgcCookieConsentService, NgcInitializeEvent, NgcStatusChangeEvent, NgcNoCookieLawEvent } from 'ngx-cookieconsent';
import { interval, Subscription } from 'rxjs';
import { stringify } from 'querystring';
import { Title } from '@angular/platform-browser';

import { NavigationStart, Router } from '@angular/router';

export let browserRefresh = false;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {

  private updateSubscription: Subscription;
  timeInterval:any;

  public myDate:Date;

  constructor(private auth:AuthService, private title: Title , private router: Router ){
      this.subscription = router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        browserRefresh = !router.navigated;
      }
  });
  }

  subscription: Subscription;
  ngOnInit() {
    this.title.setTitle('PermID');
     this.auth.autoAuthData();

    if(!(this.auth.getAuthStatus())){
      const tempToken = sessionStorage.getItem("tempToken");
      const expireDate = sessionStorage.getItem("expiresAt");

      if(!tempToken || !expireDate){
        this.auth.getTempToken();
      }

      this.auth.autoTempData(tempToken, expireDate);

    }
  }

  ngOnDestroy() {

  }

  onActivate(event: Event) {
    // window.scrollTo(0, 0);
  }

}
