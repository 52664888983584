import { Component, OnInit, ViewEncapsulation, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-search-input',
  templateUrl: './search-input.component.html',
  styleUrls: ['./search-input.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SearchInputComponent implements OnInit {
  @Input() searchedKey:string = '';

  constructor(private router: Router, private route: ActivatedRoute, private title: Title) { }

  ngOnInit() {
    if(this.searchedKey){
    this.title.setTitle(this.searchedKey + ' | PermID');
    }
  }

  onSubmit(event, q) {
    this.title.setTitle(this.searchedKey + ' | PermID');
    event.preventDefault();
    if(q.search !== '') {
      this.router.navigate(['/entity-search/', q], {
        // skipLocationChange:true,
        replaceUrl: true,
        relativeTo: this.route
        // queryParams: q
      });
    }
  }
}
