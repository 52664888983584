import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { DropdownDirective } from './shared/dropdown.directive';
import { HomeComponent } from './home/home.component';
import { FooterComponent } from './footer/footer.component';
import { AboutComponent } from './about/about.component';
import { ContactComponent } from './contact/contact.component';
import { EntitySearchComponent } from './entity-search/entity-search.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { LoadingComponent } from './loading/loading.component';
import {MatTabsModule} from '@angular/material/tabs';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SearchInputComponent } from './search-input/search-input.component';
import {MatPaginatorModule} from '@angular/material/paginator';
import {NgxPaginationModule} from 'ngx-pagination';
import { SearchEntityByIdComponent } from './search-entity-by-id/search-entity-by-id.component';
import { LastwordPipe } from './lastword.pipe';
import { RecordMatchingComponent } from './record-matching/record-matching.component';
import { DownloadComponent } from './download/download.component';
import { CustomDatePipe } from './shared/custom-date.pipe';
import { TaggingComponent } from './tagging/tagging.component';
import { PopupComponent } from './shared/popup/popup.component';
import { ViewDataComponent } from './view-data/view-data.component';

import {
  MatDialogModule,
MatDialogRef,
MAT_DIALOG_DATA} from '@angular/material/dialog';
import{MatProgressBarModule} from '@angular/material/progress-bar';
import{MatCheckboxModule} from '@angular/material/checkbox';
import{MatRadioModule} from '@angular/material/radio';
import{MatTableModule} from '@angular/material/table';
import {MatInputModule} from '@angular/material/input';
import {MatExpansionModule} from '@angular/material/expansion';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { MatTabLabel } from '@angular/material/tabs';
import {MatIconModule} from '@angular/material/icon';
import {MatTooltipModule} from '@angular/material/tooltip';
import {NgbPaginationModule} from '@ng-bootstrap/ng-bootstrap';
import { FaqComponent } from './faq/faq.component';
import { TermsComponent } from './terms/terms.component';
import { ValidateUserComponent } from './validate-user/validate-user.component';
import { AuthInterceptor } from './shared/services/auth-interceptor';
import { RdfResultsComponent } from './rdf-results/rdf-results.component';
import { TaggingModelService } from './shared/tagging-model.service';
import { DataService } from './data.service';
import { ExtractDocumentPipe } from './rdf-results/extract-document.pipe';
import { SafeHtmlPipe } from './shared/safe-html.pipe';
import { TextHighlightDirective } from './shared/text-highlight.directive';
import { EntityPopupComponent } from './shared/entity-popup/entity-popup.component';
import { errorPopupComponent } from './shared/error-popup/error-popup.component';
import {NgcCookieConsentModule, NgcCookieConsentConfig} from 'ngx-cookieconsent';
import { environment } from '../environments/environment';
import { PopoverComponent } from './shared/popover/popover.component';
import { SearchinfoPopupComponent } from './shared/searchinfo-popup/searchinfo-popup.component';

// const cookieConfig:NgcCookieConsentConfig = {
//   cookie: {
//     domain: environment.domain,
//     name: "Refinitiv_privacy_policy_banner",
//     expiryDays:1
//   },
//   palette: {
//     popup: {
//       background: '#e9e9e9',
//       text: '#404040'
//     }
//   },
//   position: 'top',
//   theme: 'classic',
//   type: 'opt-in',
//   revokable:true,
//   layout: 'permID-custom-layout',
//   layouts: {
//     "permID-custom-layout": '{{messagelink}}{{compliance}}'
//   },
//   elements:{
//     messagelink: `
//     <span id="cookieconsent:desc" class="cc-message" style="padding:0px 20px; margin:0px 20px;">
//     <span style=" font-size: 15px;display: block;margin-bottom: 8px;font-weight: 700;color: #0d0d0d;">Our Policy Towards The Use Of Cookies</span>
//     <p style="padding-right:40px; font-size: 15px;">{{message}}</p>
//       <a target="_self" href="{{privacyPolicyHref}}" style="text-decoration:underline" >{{privacyPolicyLink}}</a>
//       <a target="_self" href="{{cookiePolicyHref}}" style="text-decoration:underline; padding-left:20px;">{{cookiePolicyLink}}</a>
//     </span>
//     `,
//     allow:`<a style="text-decoration:none;padding:15px;" tabindex="0" class="cc-btn cc-allow">Okay to continue</a>
//     `
//   },
//   content:{
//     message: 'All Refinitiv websites use cookies to improve your online experience. They were placed on your computer when you launched this website. You can change your cookie settings through your browser.',

//     cookiePolicyLink: 'Cookie Policy',
//     cookiePolicyHref: 'https://www.refinitiv.com/en/policies/privacy-statement#cookies',

//     privacyPolicyLink: 'Privacy Statement',
//     privacyPolicyHref: 'https://www.refinitiv.com/en/policies/privacy-statement',

//     allow: 'Okay to continue',
//   }
// };


@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    DropdownDirective,
    HomeComponent,
    FooterComponent,
    AboutComponent,
    ContactComponent,
    EntitySearchComponent,
    LoadingComponent,
    SearchInputComponent,
    SearchEntityByIdComponent,
    LastwordPipe,
    RecordMatchingComponent,
    DownloadComponent,
    CustomDatePipe,
    TaggingComponent,
    PopupComponent,
    FaqComponent,
    TermsComponent,
    ValidateUserComponent,
    RdfResultsComponent,
    ExtractDocumentPipe,
    ViewDataComponent,
    SafeHtmlPipe,
    TextHighlightDirective,
    EntityPopupComponent,
    errorPopupComponent,
    PopoverComponent,
    SearchinfoPopupComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    MatTabsModule,
    BrowserAnimationsModule,
    MatPaginatorModule,
    NgxPaginationModule,
    MatDialogModule,
    NgbPaginationModule,
    MatInputModule,
    MatButtonModule,
    MatExpansionModule,
    MatRadioModule,
    MatProgressBarModule,
    MatSelectModule,
    MatCheckboxModule,
    NgbModule,
    MatTooltipModule,
    MatIconModule,
    // NgcCookieConsentModule.forRoot(cookieConfig),
    NgCircleProgressModule.forRoot({
      // set defaults here
      "showSubtitle": false,
      outerStrokeColor: "#001eff",
      innerStrokeColor: "#001eff",
      animationDuration: 300
    })
  ],
  entryComponents: [
    PopupComponent,
    EntityPopupComponent,
    errorPopupComponent,
    PopoverComponent,
    SearchinfoPopupComponent
  ],
  providers: [{provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi:true}, TaggingModelService , DataService ],
  bootstrap: [AppComponent]
})
export class AppModule { }
