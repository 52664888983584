import { ComponentFactory, ComponentFactoryResolver, ComponentRef, Directive, HostListener, ViewContainerRef } from '@angular/core';
import { EntityPopupComponent } from './entity-popup/entity-popup.component';

@Directive({
  selector: '[appTextHighlight]'
})
export class TextHighlightDirective {

  constructor() { }

  @HostListener('mouseenter') onMouseEnter() {
    alert("Don't touch my bacon!");
  }

  // factory: ComponentFactory<EntityPopupComponent> = this.resolver.resolveComponentFactory(EntityPopupComponent);
  // entityPopup: ComponentRef<EntityPopupComponent> = this.viewContainerRef.createComponent(this.factory);

}
