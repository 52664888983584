import { Inject,AfterContentChecked, AfterViewInit, Component, ComponentFactoryResolver, ComponentRef, ElementRef, EventEmitter, HostBinding, HostListener, Input, OnInit, OnChanges , SimpleChange , Output, Renderer2, ViewChild, ViewContainerRef, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-popover1',
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.scss']
})
export class PopupComponent implements OnInit {

  @Input() category:any;
  @Input() relCategory:any;
  @Input() categoryType:any;
  @Input() popupInstanceId:any=0;

  constructor() { }

  ngOnInit() {
    //console.log("Category is :", this.category);
  }

} // PopupComponent
