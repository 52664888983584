import { Inject,AfterContentChecked, AfterViewInit, ContentChild,Component, ComponentFactoryResolver, ComponentRef, ElementRef, EventEmitter, HostBinding, HostListener, Input, OnInit, OnChanges , SimpleChange , Output, Renderer2, ViewChild, ViewContainerRef, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogConfig, MatDialogRef} from '@angular/material/dialog';
import { MatTabChangeEvent } from '@angular/material/tabs';
import {FormControl} from '@angular/forms';
import {rightClickDetected , leftClickDetected, setrightClickDetected , setleftClickDetected} from "../../globals";
import { ScrollService } from '../services/scroll.service';

@Component({
  selector: 'app-popover',
  templateUrl: './popover.component.html',
  styleUrls: ['./popover.component.scss']
})
export class PopoverComponent implements OnInit 
{
constructor(private scrollService: ScrollService,private resolver: ComponentFactoryResolver,
  private render: Renderer2,private viewContainerRef: ViewContainerRef,){}
  @Input() category:any;
  @Input() relCategory:any;
  @Input() categoryType:any;
  @Input() OtherCategory:any[]=[];
  @Input() OtherCategoryIndex:any=0;
  @Input() popupInstanceId:any=0;
  @Input() AssignmentDone:boolean = false;
  @Input() relAssignmentDone:boolean = false;
  @Input() srorelCategory:any;
  @Input() rorelCategory:any;
  @Input() rO_relAssignmentDone:boolean = false;
  @Input() sRO_relAssignmentDone:boolean = false;
  @Output() rightClickDetect:boolean = false;
  @Output() leftClickDetect:boolean = false;
  @ViewChild('scroll', {static: false}) scroll : ElementRef;
  @ViewChild('tabone', {static: false}) tabone : ElementRef;
  @ViewChild('tabtwo', {static: false}) tabtwo : ElementRef;
  @ViewChild('tabthree', {static: false}) tabthree : ElementRef;
  @ViewChild('relTabdata', {static: false}) relTabdata : ElementRef;
  @ViewChild('rorelTab', {static: false}) rorelTab : ElementRef;
  @ViewChild('srorelTab', {static: false}) srorelTab : ElementRef;
  @ViewChild('topdiv', {static: false}) topdiv : ElementRef;
  @ViewChild('maindiv', {static: false}) maindiv: ElementRef;
  // @ViewChild('scroll') scroll: ElementRef;
  // @ViewChild('tabone') tabone: ElementRef;
  // @ViewChild('tabtwo') tabtwo: ElementRef;
  // @ViewChild('tabthree') tabthree: ElementRef;
  // @ViewChild('relTabdata') relTabdata: ElementRef;
  // @ViewChild('rorelTab') rorelTab: ElementRef;
  // @ViewChild('srorelTab') srorelTab: ElementRef;
  // @ViewChild("topdiv") topdiv: ElementRef;
  @Output() showId:boolean =false;
  @Output() myUpdatedStatus = new EventEmitter<string>();
  hidepopup: boolean = true;
  selectedTabIndex: any;
  topVal:boolean = true;
  topValue = "Top";
  scrollBar:boolean = false;
  selected:any;
  tabTitle:any;
  relTabTitle:any;
  rorelTabTitle:any;
  srorelTabTitle:any;
  classNameStr:any;
  showbtn:boolean = false;

  ngOnInit() 
  {
    this.hidepopup = true;
    // console.log("srorelCategory:::",this.srorelCategory);
    // console.log("srorelCategory: stringify::",JSON.stringify(this.srorelCategory));
    // console.log("rorelCategory:::",this.rorelCategory);
    // console.log("rorelCategory: stringify::",JSON.stringify(this.rorelCategory));
    // console.log("Category:::",this.category);
    // console.log("Category: stringify::",JSON.stringify(this.category));
    // console.log("relCategory:::",this.relCategory);
    // console.log("relCategory: stringify::",JSON.stringify(this.relCategory));
    // this.topFunction();
    this.selected = 0;
    if(this.category !=null)
      {
        this.tabTitle=this.category.name;
      }
      
    if (this.relCategory != null)
      {
        if(this.relCategory.name !=null)
        {
          this.relTabTitle=this.relCategory.name;
        }
        else
        {
          this.relTabTitle=this.relCategory._type;
        }
      } 
    if (this.rorelCategory != null)
      {
        if(this.rorelCategory.name !=null)
        {
          this.rorelTabTitle=this.rorelCategory.name;
        }
        else
        {
          this.rorelTabTitle=this.rorelCategory._type;
        }
      } 
    if (this.srorelCategory != null)
      {
        if(this.srorelCategory.name !=null)
        {
          this.srorelTabTitle=this.srorelCategory.name;
        }
        else
        {
          this.srorelTabTitle=this.srorelCategory._type;
        }
      } 
  } // ngOnInit

   
  onTabChange(event: MatTabChangeEvent) {
    setTimeout(() => {
        this.selected = event.index+1;
        // console.log("onTabChange Called");
    });
  }
  onScroll(event: any) {
    if (event.target.offsetHeight >=245) {
        this.scrollBar = true;
    } else {
      this.scrollBar = false;
    }
  }
  scrolltop(val: any){
    // document.getElementById("myDIV").scrollIntoView();
    this.topdiv.nativeElement.scrollIntoView({behaviour: 'smooth'});
     document.getElementById("maindiv").style.display = 'block';
    //console.log("val::", val)
  }
  // scrolltop(el) {
  //   console.log(el)
  //  el.scrollIntoView({behavior:"smooth"});
  // }
  topFunction(el: HTMLElement) {
      el.scrollIntoView();
      this.scrollBar = false;
  }
  srorelTabClick(event: any,eventValue: any){
    // document.getElementById("srorelTab").scrollIntoView();
     // document.getElementById("maindiv").style.display = 'block';
    //document.getElementById("srorel").style.position = 'absolute';
    this.srorelTab.nativeElement.scrollIntoView(event);
    // this.scrollService.scrollToElementById(event);
   //console.log("element id srorelTabClick: ",event+"eventValue: any::",eventValue.target.classList[0]);
  }
  roreltabClick(event: any,eventValue: any){
    // document.getElementById("rorelTab").scrollIntoView();
     // document.getElementById("maindiv").style.display = 'block';
    //document.getElementById("rorel").style.position = 'absolute';
    this.rorelTab.nativeElement.scrollIntoView(event);
    // this.scrollService.scrollToElementById(event);
    //console.log("element id roreltabClick: ",event+"eventValue:::",eventValue.target.classList[0]);
  }
  relCatClick(event: any,eventValue: any){
     // document.getElementById("maindiv").style.display = 'block';
    // document.getElementById("relTab").style.position = 'absolute';
    this.relTabdata.nativeElement.scrollIntoView(event);
    // this.scrollService.scrollToElementById(event);
    //console.log("element id relcat: ",event+"eventValue::",eventValue.target.classList[0]);
  }
  tab3Click(event: any,eventValue: any){
    // document.getElementById("tabthree").scrollIntoView();
     // document.getElementById("maindiv").style.display = 'block';
    // document.getElementById("tabt3").style.position = 'absolute';
    this.tabthree.nativeElement.scrollIntoView(event);
    // this.scrollService.scrollToElementById(event);
    //console.log("element id tab3: ",event+"eventValue::",eventValue.target.classList[0]);
  }
  tab2Click(event: any,eventValue: any){
    // document.getElementById("tabtwo").scrollIntoView();
     // document.getElementById("maindiv").style.display = 'block';
    // document.getElementById("tab2").style.position = 'absolute';
    this.tabtwo.nativeElement.scrollIntoView(event);
    // this.scrollService.scrollToElementById(event);
    //console.log("element id tab2: ",event+"eventValue::",eventValue.target.classList[0]);
  }
  tab1Click(event: any,eventValue: any){
    // document.getElementById("tabone").scrollIntoView();
     // document.getElementById("maindiv").style.display = 'block';
    // document.getElementById("tab1").style.position = 'absolute';
    this.tabone.nativeElement.scrollIntoView(event);
    // this.scrollService.scrollToElementById(event);
    //console.log("element id tab1: ",event+"eventValue::",eventValue.target.classList[0]);
  }

  getClassName(el:any,overlapyorn:boolean)
    {
      let classNameString:String="";
      if ( el.offsetTop <= 350 && el.offsetLeft > 400)
        {
          classNameString='popup-wrapper1';
        }
        else if ( el.offsetTop <= 350 && el.offsetLeft <= 400 )
        {
          classNameString='popup-wrapper';
        }
        else if (el.offsetTop > 350 && el.offsetLeft > 400 )
        {
            if(overlapyorn)
            {
              classNameString='popup-wrapper4';
            }
            else
            {
              classNameString='popup-wrapper3';
            }
        }
        else if ( el.offsetTop > 350 && el.offsetLeft <= 400 )
        {
          classNameString='popup-wrapper2';
        }
      return String(classNameString) ;
    }

  clickdirection(direction:any)
  {
    if(String(direction)=='left')
    {
      // this.leftClickDetect=true;
      setleftClickDetected(true);
      setrightClickDetected(false); 
    }
    else if (String(direction)=='right')
    {
      // this.rightClickDetect=true; 
      setrightClickDetected(true);
      setleftClickDetected(false);
    }
  } // clickdirection

} // class