import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { AuthService } from '../shared/services/auth.service';
import { SearchService } from '../shared/services/entitySearch/search.service';

@Component({
  selector: 'app-download',
  templateUrl: './download.component.html',
  styleUrls: ['./download.component.scss']
})
export class DownloadComponent implements OnInit, OnDestroy {
  downloadResults = [];
  userIsAuthenticated = false;
  private authListnerSub: Subscription;

  constructor(private downloadService: SearchService, private auth: AuthService) { }

  ngOnInit() {
    this.downloadService.getDownloadDetails().subscribe(response => {
      // console.log(response);
      for(let i =0; i< response.length; i++) {
        let fileSize = this.formatBytes(response[i].size)
        response[i].size = fileSize;
      }
      this.downloadResults = response;
    })

    this.userIsAuthenticated = this.auth.getAuthStatus();
    this.authListnerSub = this.auth.getAuthListener().subscribe(isAuthenticated=>{
      this.userIsAuthenticated = isAuthenticated;
    })


  }

  formatBytes(bytes, decimals=2) {
    if(bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  ngOnDestroy() {
    this.authListnerSub.unsubscribe();
  }

}
