import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
  name: 'customDate'
})
export class CustomDatePipe extends DatePipe implements PipeTransform {

  transform(value: any, args?: any): any {
    let newDate: string;
    let reggie = /(\d{4})(\d{2})(\d{2})_(\d{2})(\d{2})(\d{2})/;
    let dateArray = reggie.exec(value);
    newDate = dateArray[1] + '-' + dateArray[2] + '-' + dateArray[3];
    return super.transform(newDate, args);
  }

}
