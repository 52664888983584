import { Injectable } from '@angular/core';

@Injectable()
export class TaggingModelService {

  public textInput: any;
  public fileInput:any[]=[];
  public contentClass:string;

  constructor() { }
}
