'use strict';

export const version: string="22.2.2";    
export var rightClickDetected:boolean = false;
export var leftClickDetected:boolean = false;
export function setrightClickDetected(flag: boolean) {
    rightClickDetected = flag;
}
export function setleftClickDetected(flag: boolean) {
    leftClickDetected = flag;
}