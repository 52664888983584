import { OnInit , OnDestroy} from '@angular/core';
import { Component, Input } from '@angular/core';
import { DataService } from "../../data.service";
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-errorpopup',
  templateUrl: './error-popup.component.html',
  styleUrls: ['./error-popup.component.scss']
})

export class errorPopupComponent implements OnInit, OnDestroy {

  message:string;
  subscription: Subscription;

  constructor(private data: DataService) { }

  ngOnInit() {
    this.subscription = this.data.currentMessage.subscribe(message => this.message = message)
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  newMessage() {
    this.data.changeMessage("Hello from error component")
  }

}