import { NgModule } from '@angular/core';
import { Routes, RouterModule, ExtraOptions } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { AboutComponent } from './about/about.component';
import { ContactComponent } from './contact/contact.component';
import { EntitySearchComponent } from './entity-search/entity-search.component';
import { SearchEntityByIdComponent } from './search-entity-by-id/search-entity-by-id.component';
import { RecordMatchingComponent } from './record-matching/record-matching.component';
import { DownloadComponent } from './download/download.component';
import { TaggingComponent } from './tagging/tagging.component';
import { FaqComponent } from './faq/faq.component';
import { TermsComponent } from './terms/terms.component';
import { ValidateUserComponent } from './validate-user/validate-user.component';
import { RdfResultsComponent } from './rdf-results/rdf-results.component';
import { ViewDataComponent } from './view-data/view-data.component';

const routerOptions: ExtraOptions = {
  // scrollPositionRestoration: 'enabled',
  anchorScrolling: 'enabled',
  scrollPositionRestoration: 'top',
  scrollOffset: [0, 64],
};

const routes: Routes = [
  {path:'about', component:AboutComponent},
  {path:'contact', component: ContactComponent},
  {path:'faq', component: FaqComponent},
  {path:'terms', component: TermsComponent},
  {path:'entity-search', component:EntitySearchComponent},
  {path:'record-matching', component:RecordMatchingComponent},
  {path:'download', component:DownloadComponent},
  {path:'tagging', component:TaggingComponent},
  {path:'validateUser', component:ValidateUserComponent},
  {path:'rdfResults', component:RdfResultsComponent},
  {path:'viewData', component:ViewDataComponent},
  {path:':id', component:SearchEntityByIdComponent},
  {path:'', component:HomeComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, routerOptions)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
