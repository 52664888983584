import { Component, OnInit, ViewChild, ContentChild } from '@angular/core';
//import { text } from '@angular/core/src/render3';
import { NgForm } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { NavigationEnd, NavigationExtras, Router } from '@angular/router';
//import { empty } from 'rxjs';
import { SearchinfoPopupComponent } from '../shared/searchinfo-popup/searchinfo-popup.component';
import { PopupComponent } from '../shared/popup/popup.component';
import { SearchService } from '../shared/services/entitySearch/search.service';
import { TaggingModelService } from '../shared/tagging-model.service';
import { environment } from 'src/environments/environment';
import { Title } from '@angular/platform-browser';

interface Alert {
  type: string;
  message: string;
}
const ALERTS: Alert[] = [{
  type: 'danger',
  message: 'Please upload a file or enter text in the space provided',
}];
@Component({
  selector: 'app-tagging',
  templateUrl: './tagging.component.html',
  styleUrls: ['./tagging.component.scss']
})
export class TaggingComponent implements OnInit {
  @ViewChild('documentInput', {static: false}) formInput : any;
  //@ViewChild('documentInput') formInput;
  intgelligentclick: boolean = true;
  closeResult: string;
  calaisparams: any;
  textArea:string = '<Document>\n<Title> PLACE_YOUR_TITLE_HERE </Title>\n<Body> PLACE_YOUR_BODY_HERE </Body>\n</Document>';
  textInput:string ='';
  profileValue:string = "News";
  premiumContent:string = "premiumResults";
  fileInput: File = null;
  myFiles:String[] = [];
  uploadedFile: boolean = false;
  errormessage: boolean = false;
  documentType: String[] = ["News", "Research"];
  showResultsType: String[] = ["Show free results","Show premium results"]
  resultsTypeValue:string = "Show premium results";
  popupService: any;
  alerts: Alert[];
  filesize: any;
  largefilesize: any;
  actualUrl: any;
  api = environment.domain;
  
  // premiumType: String[] = ["Show premium results", "show free results"];
  constructor(private tagService: SearchService, private router: Router, private taggingDetail: TaggingModelService, public dialog: MatDialog, private title: Title) {

   }
   close(alert: Alert) {
    this.alerts.splice(this.alerts.indexOf(alert), 1);
  }

  ngOnInit() {
    this.title.setTitle('Calais Tagging' + ' | PermID');
    this.actualUrl = this.api;
    if(this.profileValue == "News"){
      this.textInput = this.textArea;
    } else {
      this.textInput = '';
    }

  }

  tagingImg(){
    this.intgelligentclick = false;
  }

  acceptedTypes = {'application/pdf': true, 'text/xml': true, 'application/vnd.ms-excel' : false, 'text/plain': true, 'text/html': true}
  pdfFilerestrict = {'text/xml': true, 'application/vnd.ms-excel' : false, 'text/plain': true, 'text/html': true}
  pdfFileRestricttype(event) {
    // this.fileInput = file.item(0);

    for (let i = 0; i <event.target.files.length; i++) {
      const file = event.target.files[i];
      this.filesize = event.target.files[i].size;
      if(this.pdfFilerestrict[event.target.files[i].type] === true){
        this.myFiles.push(file);
        this.uploadedFile = true;
        this.textInput = null;
      }
      else{
        alert('Unrecognized format - please use PDF, XML or HTML');
        this.uploadedFile = false;
      }
    }
  }
  handleFileInput(event) {
    // this.fileInput = file.item(0);

    for (let i = 0; i <event.target.files.length; i++) {
      const file = event.target.files[i];
      this.filesize = event.target.files[i].size;
      if(this.acceptedTypes[event.target.files[i].type] === true){
        this.myFiles.push(file);
        this.uploadedFile = true;
        this.textInput = null;
      }
      else{
        alert('Unrecognized format - please use PDF, XML or HTML');
        this.uploadedFile = false;
      }
    }
  }

  clearDemo() {
    this.textInput = this.textArea;
    this.uploadedFile = false;
    this.myFiles = [];
    setTimeout(()=>{
      this.alerts.pop();
    }, );
  }

  OnTag(form: NgForm) {

    if(form.invalid){
     this.alerts = Array.from(ALERTS);
      setTimeout(()=>{
        this.alerts.pop();
      }, 3000);
      return;
    }

    if(this.textArea == ''){
      this.alerts = Array.from(ALERTS);
      setTimeout(()=>{
        this.alerts.pop();
      }, 3000);
      return;
    }
    this.errormessage = false;
    this.textInput = form.value.content;

    this.taggingDetail.contentClass = form.value.type;
    this.taggingDetail.textInput = this.textInput;
    this.taggingDetail.fileInput = this.myFiles;
    // this.taggingDetail.fileInput.push(this.fileInput);

    this.router.navigate(['/rdfResults']);

  }
  openPopup() {
    this.dialog.open(SearchinfoPopupComponent, {
      width: '600px'
    });
  }

}
