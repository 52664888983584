import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'extractDocument'
})
export class ExtractDocumentPipe implements PipeTransform {

  transform(value: any, args?: any): any {
  //   if ((value===null) || (value===''))
  //      return false;
  //   else
  //     value = value.toString();
  //     return value.replace(/<[^>]*>/g, '\n');

  if (!args) {
    return value;
  }
  var re = new RegExp(args, 'gi'); //'gi' for case insensitive and can use 'g' if you want the search to be case sensitive.
  //console.log(value.replace(re, "<mark>$&</mark>"));
  return value.replace(re, "<mark>$&</mark>");
  }

}
